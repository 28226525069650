import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import '../styles/expertise.css'
import '../styles/who.css'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


const Expertise = () =>{

    const trophy = 'https://i.pcmag.com/imagery/articles/01s8lOzd24t1s3pHCdDtLUA-1..v1569470602.jpg'
    //https://codepen.io/alvarotrigo/pen/KKvOGNb
    //Reference

    const inViewport = () =>{
        const items = document.querySelectorAll('.reveal');

        items.forEach((box)=>{
            const rect = box.getBoundingClientRect();
            if (rect.top < 175) {
                box.style.opacity = 0;
            }
            else if(rect.bottom<0){
                box.style.transform='translateX(150px)'
                box.style.opacity = 0;
            }
            else {
                box.style.opacity = 1;
            }
        })
    }

    useEffect(() => {
        window.addEventListener('scroll', inViewport, { passive: true });

        return () => {
            window.removeEventListener('scroll', inViewport);
        };
    }, []);

    return(<section className={'expertise_section'}>
            <div className="expertise_container">
                    <div className="expertise_info">

                            {/*<div className="elfsight-app-a46d7e27-68fb-48d0-b0b9-a4d9750445d9 something"></div>*/}
                            <div className="points">

                                <ul className={'reveal'}>
                                    <div className="icon">
                                        <i className="uil uil-print"></i>
                                    </div>
                                    <div className="list">
                                    <h3>Volume Production via 3D Printing:</h3>
                                    <li>Circular Production</li>
                                    <li>3D Printer Design and Manufacture</li>
                                    <li>3D Printing Materials and Techniques</li>
                                    <li>Lights out print automation</li>
                                    </div>

                                </ul>
                                <ul className={'reveal'}>
                                    <div className="icon">
                                        <i className="uil uil-sun"></i>
                                    </div>
                                    <div className="list">
                                    <h3>Solar Energy System Development:</h3>
                                    <li>Patent Pending Solar Enclosures</li>
                                    <li>On Vehicle Solar Systems</li>
                                    <li>Hybrid Grid Systems</li>
                                    </div>

                                </ul>
                                <ul className={'reveal'}>
                                    <div className="icon">
                                        <i className="uil uil-water"></i>
                                    </div>
                                    <div className="list">
                                    <h3>Marine Propulsion Product Development:</h3>
                                    <li>Ultra efficient sub 10kg 30g/w</li>
                                    <li>Ultra efficient sub 20kg 35g/w</li>
                                    </div>

                                </ul>
                                <ul className={'reveal'}>
                                    <div className="icon">
                                        <i className="uil uil-battery-bolt"></i>
                                    </div>
                                    <div className="list">
                                    <h3>Battery Management System and Cell Bank Development:</h3>
                                    <li>PCB Design & Assembly</li>
                                    <li>Solder-free cell solutions</li>
                                    </div>
                                </ul>
                            </div>
                        <div className="expertise_intro ">
                            <h2 className={'reveal'}>Expertise</h2>
                            <p>
                                    <span className="reveal">
                                    Since 2018, we've been working towards our objective and have developed a diverse range of expertise to
                                        enable us to deliver a high-performance watercraft at an affordable cost. </span>
                                <br/>
                                <br/>
                                <span className="reveal">
                                        We've achieved a strong
                                        command in various areas, including low-volume, low-cost FDM production of larger and stronger objects,
                                        electronics, renewable and high-efficiency energy systems design, and some aspects of propulsion and materials.</span>
                                <br/>
                                <br/>
                                <span className="reveal">
                                     Our portfolio of expertise positions us well to create a craft with exemplary
                                    characteristics in all aspects of conception, and we look forward to bringing our vision to life.
                                        </span>
                            </p>
                        </div>
                    </div>

            </div>

        </section>
    )
}
export default Expertise;