import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import '../styles/who.css'
import Tree2 from "../Tree2";
import Tree1 from "../Tree1";
import data from '../../utils/marineList.json'
import '../styles/Marine.css'
import {Carousel} from "react-responsive-carousel";
import Modal from "react-modal";
import image_thing from "../../assets/mini.svg";

const customStyles = {
    overlay:{
        zIndex:"100000",
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height:'90vh',
        width:'90vw',
        overflow:'hidden',

        overflowY:'auto',
        backgroundColor:'#ffffff'

    },
};

const Marine = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [modalOpen,changeModal] = useState(false);
    const [modalInfo,changeModalInfo] = useState({
        name:'Placeholder',
        year:'2022',
        images:[image_thing],
        features:['nothing','dfrg'],
        specification:['nothing','dfrg'],
        sizing:[''],
    });

    useEffect(()=>{
        if(modalOpen){
            document.body.style.overflowY='hidden';
        }
        else{
            document.body.style.overflowY='auto';
        }
    },[modalOpen])

    function closeModal() {
        changeModal(false);
    }

    useEffect(()=>{
        window.addEventListener("resize", e => {setWidth(window.innerWidth)});
    })

    const getFeatures = ()=>{
        const items = modalInfo.features.map((thing,i) =>
            <li key={i}>{thing}</li>
        )
        return (<ul className={'features'}>
            {items}
        </ul>)

    }

    const getSpecs = () =>{
        const items = modalInfo.specification.map((thing,i) =>
            <li key={i} >{thing}</li>
        )
        return (<ul className={'specs'}>
            {items}
        </ul>)

    }
    const getSizing = () =>{
        const items = modalInfo.sizing.map((thing,i) =>
            <li key={i}>{thing}</li>
        )
        return (<ul className={'sizing'}>
            {items}
        </ul>)

    }

    useEffect(()=>{
        window.addEventListener("resize", e => {setWidth(window.innerWidth)});
    })



    const inViewport = () =>{
        const box = document.querySelector('.reveal');
        const rect = box.getBoundingClientRect();
        if (rect.top < 175) {
            box.style.opacity = 0;
        }
        else if(rect.bottom>window.innerHeight){
            box.style.opacity = 0;
        }
        else {
            box.style.opacity = 1;
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', inViewport, { passive: true });

        return () => {
            window.removeEventListener('scroll', inViewport);
        };
    }, []);




    function onNodeClick(datas) {
        const info = datas
        changeModal(true)
        changeModalInfo(info)
    }


    return(<section id={'marine_section'}>
        <Modal
            isOpen={modalOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="info_container" >
                <div className="imageContainer">
                    <Carousel showIndicators={false} width={'100%'} dynamicHeight={true} showStatus={false}>
                        {modalInfo.images.map((image, i) => {
                            return (<img src={image} key={i} className={'carouselImg'} alt={'product image'}/>)
                        })
                        }
                    </Carousel>
                </div>
                <div className="detailContainer">
                    <h3>LAZERTHRUST</h3>
                    <h2>{modalInfo.name}</h2>
                    <h4>{modalInfo.price}</h4>
                    <h5>Vat and shipping included!</h5>
                    <p>{modalInfo.description}</p>
                    <div className="shop-social">
                        <a href="https://www.instagram.com/lazerthrust/?hl=en" className={'social-links'}>

                            <i className="uil uil-instagram-alt">
                            </i>
                        </a>
                        <a href="https://www.youtube.com/channel/UCTt7KfAF7aMuyxZuRejDy7Q" className={'social-links'}>
                            <i className="uil uil-youtube">
                            </i>
                        </a>
                        <a href="https://www.facebook.com/LAZERTHRUST/" className={'social-links'}>
                            <i className="uil uil-facebook">

                            </i>
                        </a>
                        <a href="https://twitter.com/lazerthrust" className={'social-links'}>
                            <i className="uil uil-twitter">
                            </i>
                        </a>
                    </div>

                    {/*<a href={modalInfo.link} className={'preorderButton'}>Preorder!</a>*/}
                    {/*<button className={'kickButton'}><span className={'kickstarter'}>KICKSTARTER</span></button>*/}
                </div>


                <button className={'closeModal'} onClick={closeModal}><i className="uil uil-multiply"></i></button>
            </div>

            <div className="contactForm">
                <h2>Get Notified!</h2>
                    {/*<form method={"post"} action="https://formsubmit.co/preorder@lazerthrust.com">*/}
                <form method="post" action="">
                    <input type="hidden" name="_captcha" value="false" />
                    <input type="hidden" name="_template" value="table" />
                    <input type="text" name="_honey" style={{display:"none"}} />
                    <input type="text" className="form-control" placeholder={'Name'} name="name" id="firstName" required />
                    <input type="email" className="form-control" placeholder={'Email'} name="email" id="emailInfo" required />
                    <input type="text" className="form-control" name="phone" id="phoneNumber" placeholder="Phone number" required />
                    <input type="text" name="product" style={{display:"none"}} value={modalInfo.name} />
                     <input id="submit" type="submit" value="Submit" />
                </form>
            </div>

            {/*<Tabs>*/}
            {/*    <TabList>*/}
            {/*        <Tab>Features</Tab>*/}
            {/*        <Tab>Specs</Tab>*/}
            {/*        <Tab>Sizing</Tab>*/}
            {/*    </TabList>*/}

            {/*    <TabPanel>*/}
            {/*        <div className="features">*/}
            {/*            {getFeatures()}*/}
            {/*        </div>*/}
            {/*    </TabPanel>*/}
            {/*    <TabPanel>*/}
            {/*        <div className="specs">*/}
            {/*            {getSpecs()}*/}
            {/*        </div>*/}
            {/*    </TabPanel>*/}
            {/*    <TabPanel>*/}
            {/*        {getSizing()}*/}
            {/*    </TabPanel>*/}
            {/*</Tabs>*/}
        </Modal>
        <div className={'marine_container '}>
            <div className="marine_info">
                <h2 className={'reveal'}>Marine</h2>
                <p> <span className="reveal">At LAZERTHRUST, we are revolutionizing marine technology by prioritizing energy efficiency.
                    We've partnered with leading motor manufacturers to develop two maintenance-free propulsion options that can
                    integrate with other parts.</span>
                    <br/>

                    <span className="reveal">To power these propulsion systems, we've created innovative micro battery management
                        systems that allow for easy solar and USB-C charging. We focus on reusing all components and materials at
                        the end of a product's life.</span>
                    <br/>
                    <span className="reveal">All our marine products are made with 3D printed TPU-based materials,
                        which are recycled and recyclable. We are committed to sustainability and environmental responsibility in all aspects of our manufacturing process.</span>
                    </p>

            </div>
            {/*{width < 800 ? <Tree1 list={data} /> : <Tree2*/}
            {/*    onNodeClick={onNodeClick}*/}
            {/*    data={data}*/}
            {/*    />}*/}
            <Tree2 onNodeClick={onNodeClick} data={data} />
        </div>


    </section>)
}

export default Marine;