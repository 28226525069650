import React, {useState, useEffect, useRef, useLayoutEffect} from 'react';
import '../styles/brands.css'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import image1 from '../../assets/basf.png'
import image2 from '../../assets/fse.png'
import image3 from '../../assets/kjn.jpg'
import image4 from '../../assets/victron.svg'
import image5 from '../../assets/lulzbot.png'
import image6 from '../../assets/tmotor.png'
import image7 from '../../assets/ukri.png'
import image8 from '../../assets/british-marine-logo.png'
const Brands = ()=> {

    const inViewport = () =>{
        const items = document.querySelectorAll('.revealUp2');
        items.forEach((box)=>{
            const rect = box.getBoundingClientRect();
            if (rect.top < 175) {
                box.style.opacity = 0;
            } else {
                box.style.opacity = 1;
            }
        })
    }

    useEffect(() => {
        window.addEventListener('scroll', inViewport, { passive: true });

        return () => {
            window.removeEventListener('scroll', inViewport);
        };
    }, []);

    return(<section className={'brand_section'}>
        <div className="brand_container ">
            <h2 className={'revealUp2'}>Our Partners</h2>
            <div className="logos">
                <a href="https://www.basf.com/gb/en.html" target="_blank"><img className="revealUp2" src={image1} alt="BASF" /></a>
                <a href="https://www.thefsegroup.com/" target="_blank"><img className="revealUp2" src={image2} alt="FSE"/></a>
                <a href="https://www.aluminium-profile.co.uk/" target="_blank"><img className="revealUp2" src={image3} alt="KJN"/></a>
                <a href="https://www.victronenergy.com/" target="_blank"><img className="revealUp2" src={image4} alt="Victron"/></a>
                <a href="https://lulzbot.com/" target="_blank"><img className="revealUp2" src={image5} alt="Lulzbot"/></a>
                <a href="https://store.tmotor.com/" target="_blank"><img className="revealUp2" src={image6} alt="T-motor"/></a>
                <a href="https://www.ukri.org/" target="_blank"><img className="revealUp2" src={image7} alt="UKRI"/></a>
                <a href="https://britishmarine.co.uk/" target="_blank"><img className="revealUp2" src={image8} alt="British Marine"/></a>
            </div>
        </div>
    </section>)
}

export default Brands;