import * as THREE from 'three';
import React, {Component, useEffect} from "react";
import './app.css'
import Wave3 from './Wave3'
import SwitchUp from "../SwitchUp";
import gsap from "gsap";
import './app.css'
import Footer from '../sections/Footer'



class Night extends Component {

    componentDidMount(){

        const night = () => {
            console.log(this.props.hour)
            const canvas = document.querySelector('canvas.webgl')
// Scene
            const scene = new THREE.Scene()

            /**
             * Objects
             */
            const objectsDistance = 4

// Material
            const material = new THREE.MeshToonMaterial({color: '#ffeded'})

// Meshes
            const mesh1 = new THREE.Mesh(
                new THREE.TorusGeometry(1, 0.4, 16, 60),
                material
            )
            let material2 = new THREE.MeshBasicMaterial({
                color: 0xffffff,
                wireframe: true
            });


            const mesh2 = new THREE.Mesh(
                new THREE.BoxGeometry(1, 1, 1),
                material2
            )

            // const mesh3 = new THREE.Mesh(
            //     new THREE.TorusGeometry(1, 0.4, 16, 60),
            //     material
            // )

            // let item = 'dfg';
            // loader.load(asset,function (object){
            //     console.log(object);
            //     object.position.x= - objectsDistance * 2
            //     item= object
            //     scene.add(object)
            // })


            mesh1.position.y = -objectsDistance * 0
            mesh2.position.y = -objectsDistance * 0.8


            mesh1.position.x = 2
            mesh2.position.x = -2


            const sectionMeshes = [mesh1, mesh2]
            // const sectionMeshes = [ mesh1]

            // scene.add(mesh1, mesh2)

            // scene.add(mesh1)

            /**
             * Particles
             */
// Geometry
            const particlesCount = 200
            const positions = new Float32Array(particlesCount * 3)

            for (let i = 0; i < particlesCount; i++) {
                positions[i * 3 + 0] = (Math.random() - 0.5) * 10
                positions[i * 3 + 1] = objectsDistance * 0.5 - Math.random() * objectsDistance * sectionMeshes.length *0.2
                positions[i * 3 + 2] = (Math.random() - 0.5) * 10
            }

            const particlesGeometry = new THREE.BufferGeometry()
            particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))


// Material
            const particlesMaterial = new THREE.PointsMaterial({
                color: '#ffeded',
                sizeAttenuation: true,
                size: 0.03
            })


// Points
            const particles = new THREE.Points(particlesGeometry, particlesMaterial)
            scene.add(particles)

            /**
             * Lights
             */
            const directionalLight = new THREE.DirectionalLight('#ffffff', 2)
            directionalLight.position.set(1, 1, 1)
            scene.add(directionalLight)

            /**
             * Sizes
             */
            const sizes = {
                width: window.innerWidth,
                height: window.innerHeight
            }

            window.addEventListener('resize', () => {
                // Update sizes
                sizes.width = window.innerWidth
                sizes.height = window.innerHeight

                // Update camera
                camera.aspect = sizes.width / sizes.height
                camera.updateProjectionMatrix()

                // Update renderer
                renderer.setSize(sizes.width, sizes.height)
                renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
            })

            /**
             * Scroll
             */
            let scrollY = window.scrollY
            let currentSection = 0

            window.addEventListener('scroll', () => {
                scrollY = window.scrollY
                const newSection = Math.round(scrollY / sizes.height)

                if (newSection !== currentSection) {
                    currentSection = newSection

                    gsap.to(
                        sectionMeshes[currentSection].rotation,
                        {
                            duration: 1.5,
                            ease: 'power2.inOut',
                            x: '+=6',
                            y: '+=3'
                        }
                    )
                }
            })





            /**
             * Cursor
             */
            const cursor = {}
            cursor.x = 0
            cursor.y = 0

            window.addEventListener('mousemove', (event) => {
                cursor.x = event.clientX / sizes.width - 0.5
                cursor.y = event.clientY / sizes.height - 0.5
            })

            /**
             * Camera
             */
// Group
            const cameraGroup = new THREE.Group()
            scene.add(cameraGroup)

// Base camera
            const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
            camera.position.z = 6
            cameraGroup.add(camera)

            /**
             * Renderer
             */
            const renderer = new THREE.WebGLRenderer({
                canvas: canvas,
                alpha: true
            })
            renderer.setSize(sizes.width, sizes.height)
            renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

            /**
             * Animate
             */
            const clock = new THREE.Clock()
            let previousTime = 0

            const tick = () => {
                const elapsedTime = clock.getElapsedTime()
                const deltaTime = elapsedTime - previousTime
                previousTime = elapsedTime

                // Animate meshes
                for (const mesh of sectionMeshes) {
                    mesh.rotation.x += deltaTime * 0.1
                    mesh.rotation.y += deltaTime * 0.12
                }

                // Animate camera
                camera.position.y = -scrollY / sizes.height * objectsDistance

                const parallaxX = cursor.x * 0.5
                const parallaxY = -cursor.y * 0.5

                cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * 5 * deltaTime
                cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * 5 * deltaTime

                // Render
                renderer.render(scene, camera)

                // Call tick again on the next frame
                window.requestAnimationFrame(tick)
            }

            tick()

        }
        // night()

    }


    render() {
        return (
            <div className={'App'}>
                <canvas className="webgl"></canvas>
                <Wave3 hour={this.props.time} />
                <SwitchUp />


            </div>
        )
    }

}

export default Night;