import React, {useLayoutEffect} from "react";
import '../styles/footer.css'
import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


const Footer = ()=> {


    return(
        <footer>
            <div className={'footer_container'}>
                <div className="info ">
                    <div className="location left_mar">
                        <p><i className="uil uil-map-marker"></i></p>
                        <a href="https://goo.gl/maps/FSbovEyAfRSpiNz3A">Ground Floor, St Marys Works, Norwich NR3 1QA
                        </a>
                    </div>
                    <div className="number left_mar">
                        <p><i className="uil uil-phone"></i></p>
                        <a href="tel:+441603765995">+44 1603 765995</a>
                    </div>

                    <div className="email">
                        <p><i className="uil uil-envelope"></i></p>
                        <a href="mailto:web@lazerthrust.co.uk">web@lazerthrust.co.uk</a>
                    </div>

                </div>
                <div className="social_media left_mar">
                    <a href="https://www.instagram.com/lazerthrust/?hl=en" className={'social-links'}>

                        <i className="uil uil-instagram-alt">
                        </i>
                    </a>
                    <a href="https://www.youtube.com/channel/UCTt7KfAF7aMuyxZuRejDy7Q" className={'social-links'}>
                        <i className="uil uil-youtube">
                        </i>
                    </a>
                    <a href="https://www.facebook.com/LAZERTHRUST/" className={'social-links'}>
                        <i className="uil uil-facebook">

                        </i>
                    </a>
                    <a href="https://twitter.com/lazerthrust" className={'social-links'}>
                        <i className="uil uil-twitter">
                        </i>
                    </a>
                </div>
            </div>

        </footer>
    )
}
export default Footer;