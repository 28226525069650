import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import '../styles/who.css'
import image from '../../assets/printer.jpg'
const Main = () =>{

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(()=>{
        window.addEventListener("resize", e => {setWidth(window.innerWidth)});
    })
    const inViewport = () =>{
        const items = document.querySelectorAll('.reveal');
        const go = document.querySelector('.headerGoDown');
        const thing = go.getBoundingClientRect();

        if (window.scrollY>50){
            go.style.opacity=0;
        }

        else{
            go.style.opacity=1;
        }

        items.forEach((box)=>{
            const rect = box.getBoundingClientRect();

            if (rect.top < 175) {
                box.style.opacity = 0;
            }
            else if(rect.bottom>window.innerHeight){
                box.style.opacity = 0;
            }
            else {
                box.style.opacity = 1;
            }

        })




    }

    useEffect(() => {
        window.addEventListener('scroll', inViewport, { passive: true });

        return () => {
            window.removeEventListener('scroll', inViewport);
        };
    }, []);



    return(<section className={'mission_section'}>
            <div className="headerGoDown">
                <i className="uil uil-angle-double-down"></i>
            </div>
            <div className="mission_container">

                <div className="content_container">
                    <div className="mission_info">
                        <div className="something">
                            {/*<h2>We develop Marine and Energy Technology Products</h2>*/}
                            <div className={"reveal"}><h2>Our Mission </h2></div>
                        <p> <span className={'reveal'}>LAZERTHRUST aims to revolutionize travel with a 3D-printed electric watercraft that can journey from Norwich, UK to Amsterdam, NL in just 2 hours. </span>
                            <span className={"reveal"}>Our mission is to revive the spirit of point-to-point travel and trade lost in the hub-and-spoke model.</span>
                            <br/>
                            <br/>
                            <span className={"reveal"}>Although achieving this speed may require us to push beyond traditional limits and navigate logistical challenges upon arrival, we are determined to make this vision a reality and change the way we connect with one another through our innovative technology.</span>
                            </p>

                        </div>


                    </div>
                </div>
            </div>
        </section>
    )
}
export default Main;