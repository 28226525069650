import React, {useState, useEffect, createRef,useLayoutEffect,useRef} from 'react';
import Header from "./sections/Header";
import Main from "./sections/Main";
import Marine from "./sections/Marine";
import Energy from "./sections/Energy";
import Kickstarter from "./sections/Kickstarter";
import Brands from "./sections/Brands";
import Expertise from './sections/Expertise'
import Awards from './sections/Awards'
import './styles/switchup.css'
import Footer from "./sections/Footer";
import CardEnergy from "./sections/CardEnergy";
const SwitchUp = ()=>{
    const [mainOpen,openMain] = useState(true);
    const [marineOpen,openMarine] = useState(false);
    const [energyOpen,openEnergy] = useState(false);



    return (<div>
        <Header
            changeMarine = {openMarine}
            marine = {marineOpen}
            changeEnergy = {openEnergy}
            energy = {energyOpen}
            changeMain = {openMain}
            main = {mainOpen}
        />
        {mainOpen&&(
            <div className="sectionss">
                <Kickstarter />
                <CardEnergy />
                <Main />

                <Expertise />
                <Brands />
                <Footer />

            </div>
        )}

        {marineOpen&&(
            <div className="sectionss">
                <Marine />
            </div>
        )}
        {energyOpen&&(
            <div className="sectionss">
                <Energy />
            </div>
        )}

    </div>)
}

export default SwitchUp;