import React, {useState, useEffect, createRef,useLayoutEffect,useRef} from 'react';
import './styles/tree-style.css'
import * as d3 from 'd3';
import {OrgChart} from 'd3-org-chart'
import {renderToStaticMarkup} from "react-dom/server";
import {GLTFLoader} from "three/addons/loaders/GLTFLoader";
import item2 from '../assets/logo_render.glb'
import * as THREE from "three";
import gsap from "gsap";

const Tree2 = (props) => {


    const inViewport = () =>{
        const items = document.querySelectorAll('.reveal');
        const lists = document.querySelectorAll('.list-item')
        items.forEach((box)=>{
            const rect = box.getBoundingClientRect();
            if (rect.top < 175) {
                box.style.opacity = 0;
            } else {
                box.style.opacity = 1;
            }
        })
        let count =0
        lists.forEach((box)=>{
            const rect = box.getBoundingClientRect();
            if (rect.top < 160) {
                box.style.width = '0px';
                console.log('here')
            }
            else {
                box.style.width = '300px';

            }
            // console.log('count is'+count.toString() + 'top is' + rect.top.toString())
            count = count+1
        })

    }


    useEffect(() => {
        window.addEventListener('scroll', inViewport, { passive: true });

        return () => {
            window.removeEventListener('scroll', inViewport);
        };
    }, []);

    const full = () => {


        let canvas, renderer;
        const scenes = [];
        const sectionMeshes = []
        init()
        animate()

        function init() {
            canvas = document.querySelector('canvas.tha_claw')
            const canvasp = document.querySelectorAll('div.card')
            canvasp.forEach(item => {

                const scene = new THREE.Scene();

                const loader = new GLTFLoader()
                loader.load(
                    item2,
                    function (gltf) {
                        gltf.scene.rotation.x = 1.6
                        gltf.scene.scale.x = 0.4
                        gltf.scene.scale.y = 0.4
                        gltf.scene.scale.z = 0.4
                        // gltf.scene.position.y = 0.2
                        // gltf.scene.position.x = -0.05
                        // gltf.scene.rotation.y = 9.4
                        sectionMeshes.push(gltf.scene)
                        // Animate meshes
                        item.addEventListener('mouseover', () => {
                            gsap.to(
                                gltf.scene.rotation,
                                {
                                    duration: 1.5,
                                    ease: 'power2.inOut',
                                    x: '+=6.3',
                                    // y: '+=3'
                                }
                            )
                        })
                        scene.add(gltf.scene)
                    });

                const camera = new THREE.PerspectiveCamera(50, 1, 1, 10);
                camera.position.z = 2;
                scene.userData.camera = camera;

                const element = document.createElement('div');
                element.className = 'list-item';
                const sceneElement = document.createElement('div');
                element.appendChild(sceneElement);
                scene.userData.element = sceneElement;
                item.appendChild(element);


                const light = new THREE.DirectionalLight('#ffffff', 2)
                light.position.set(1, 1, 1)
                scene.add(light);

                const light2 = new THREE.DirectionalLight('#ffffff', 0.5)
                light2.position.set(-1, -1, -1)
                scene.add(light2);

                scenes.push(scene)

            })

            renderer = new THREE.WebGLRenderer({canvas: canvas, antialias: true});
            renderer.setClearColor(0xffffff, 0);
            renderer.setPixelRatio(1);
        }

        function animate() {

            render();
            requestAnimationFrame(animate);

        }

        function updateSize() {

            const width = canvas.clientWidth;
            const height = canvas.clientHeight;

            if (canvas.width !== width || canvas.height !== height) {

                renderer.setSize(width, height, false);

            }

        }

        function render() {

            updateSize();

            canvas.style.transform = `translateY(${window.scrollY}px)`;

            renderer.setScissorTest(false);
            renderer.clear();
            renderer.setScissorTest(true);
            scenes.forEach(function (scene) {

                // so something moves
                scene.rotation.y = Date.now() * 0.0005;
                // scene.rotation.x = Date.now() * 0.01;

                const element = scene.userData.element;

                // get its position relative to the page's viewport
                const rect = element.getBoundingClientRect();

                // check if it's offscreen. If so skip it
                if (rect.bottom < 0 || rect.top > renderer.domElement.clientHeight ||
                    rect.right < 0 || rect.left > renderer.domElement.clientWidth) {

                    return; // it's off screen

                }

                // set the viewport
                const width = rect.right - rect.left;
                const height = rect.bottom - rect.top;
                const left = rect.left;
                const bottom = renderer.domElement.clientHeight - rect.bottom;

                renderer.setViewport(left, bottom, width, height);
                renderer.setScissor(left, bottom, width, height);

                const camera = scene.userData.camera;

                // camera.aspect = width / height; // not changing in this example
                // camera.updateProjectionMatrix();
                //
                // scene.userData.controls.update();

                renderer.render(scene, camera);
            });

        }
    }
    useEffect(()=>{
        full();
    },[])


    useEffect(() => {
        if (!document.querySelector('.list-item')) {
            full();
        }
    });



    const getItems = () =>{

        const items = props.data.slice(1).map((thing,index) =>
            <div className="card" key={index}>
                <div className={'product_title '}>
                    <h2 className={'reveal'}>{thing.name}</h2>
                    <h3 className={'reveal'}>{thing.year}</h3>
                </div>
                <div className={'usp'}><p className={'reveal'}>{thing.description}</p>
                </div>
                <button onClick={()=>props.onNodeClick(thing)} className='register reveal'>Register Interest!</button>
            </div>
        )
        return (<>{items}</>)
    }


    return (
        <>
            {/*<svg width="10" height="10"><line x1="250" y1="0" x2="750" y2="650" stroke="black"/></svg>*/}
            <div className="funky">
                {getItems()}
            </div>

            <canvas className="tha_claw"></canvas>
        </>
    );
};



export default Tree2;