import React, {useEffect} from "react";
import './app.css'
import Wave2 from './Wave2'
import SwitchUp from "../SwitchUp";
const Day = (hour)=> {

        return (
            <div className={'App'}>
                <Wave2 currentHour={hour}/>
                <SwitchUp />
            </div>
        )


}

export default Day;