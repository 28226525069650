import * as THREE from 'three';
import React, {Component, useEffect, useState} from "react";
import './wave.css'
import { Water } from 'three/addons/objects/Water.js';
import { Sky } from 'three/addons/objects/Sky.js';

const Wave3 = (hour) =>{
    const [currentHeight,changeHeight] = useState(window.innerHeight + 200)

    // Renders canvas
    useEffect(()=>{
        function SceneManager(canvas) {
            //Calls all functions
            const scene = buildScene();
            const renderer = buildRenderer(canvas);
            const camera = buildCamera();
            const sky = buildSky();
            const sun = buildSun();
            const water = buildWater();
            const thing = buildStar()
            //Builds scene
            function buildScene() {
                const scene = new THREE.Scene();
                return scene;
            }
            // Sets up renderer for canvas
            function buildRenderer(canvas) {
                const renderer = new THREE.WebGLRenderer();
                renderer.setClearColor( 0xffffff, 0);

                renderer.setPixelRatio(1); //Change this line values for different resolution and performance
                if (window.innerWidth<600){
                    renderer.setSize(window.innerWidth, (window.innerHeight + 200));
                }
                else{
                    renderer.setSize(window.innerWidth, window.innerHeight);
                }
                // changeHeight(window.innerHeight + 200)
                canvas.appendChild(renderer.domElement);
                return renderer;
            }

            // Whole view for canvas
            function buildCamera() {
                const camera = new THREE.PerspectiveCamera( 55, window.innerWidth / window.innerHeight, 1, 20000 );
                camera.position.set( 30, 30, 100 );
                return camera;
            }

            // Sets up a vector for sky
            function buildSky() {
                const sky = new Sky();
                sky.scale.setScalar(10000);
                scene.add(sky);
                return sky;
            }

            //Function that renders geometry particles to give view of stars
            function buildStar(){
                const objectsDistance = 4
                const particlesCount = 500
                const positions = new Float32Array(particlesCount *3 )

                for (let i = 0; i < particlesCount; i++) {
                    positions[i * 3 + 0] = (Math.random() - 0.5) * 500
                    positions[i * 3 + 1] = objectsDistance * 0.5 - Math.random() * objectsDistance * 3*-4
                    positions[i * 3 + 2] = (Math.random() - 0.5) * 10
                }

                const particlesGeometry = new THREE.BufferGeometry()
                particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
                // Material
                const particlesMaterial = new THREE.PointsMaterial({
                    color: '#ffeded',
                    sizeAttenuation: true,
                    size: 0.4
                })

            // Points
                const particles = new THREE.Points(particlesGeometry, particlesMaterial)
                particles.position.set(50, 30, 0)
                // particles.scale.setScalar(10000);
                scene.add(particles)
                return particles;

            }


            // Big function for sun, changes position and such based on time
            function buildSun() {
                //Gets time and calculates position for time of day
                const time = new Date();
                let currentH=time.getHours()
                const currentM = (time.getMinutes()+1)
                currentH=currentH-5
                const calc = ((currentH*60)+currentM)
                let xval = 4-(calc*0.00486111111)
                const pmremGenerator = new THREE.PMREMGenerator( renderer );
                // xval=-10
                const sun = new THREE.Vector3();

                //Creates sizing and position
                const theta = Math.PI * (0.49 - 0.5);
                const phi = 2 * Math.PI * (0.205 - 0.5);
                //The two values between should 2 and -1.5
                sun.x = Math.cos(phi)*xval;
                sun.y = Math.sin(phi) * Math.sin(theta)*0.2;
                sun.z = Math.sin(phi) * Math.cos(theta)*0.2;
                //Gives position

                sky.material.uniforms['sunPosition'].value.copy(sun);
                sky.material.uniforms['rayleigh']=1;
                sky.material.uniforms['azimuth']=0.5;
                sky.material.uniforms['up'].value = new THREE.Vector3( 0, 0.5, 0 )
                sky.material.uniforms['turbidity'].value = 0.22
                sky.material.uniforms['mieDirectionalG'].value = 0.82

                scene.environment = pmremGenerator.fromScene(sky).texture;
                return sun;
            }

            //Sets up water textures
            function buildWater() {
                const waterGeometry = new THREE.PlaneGeometry(10000, 10000);
                const water = new Water(
                    waterGeometry,
                    {
                        textureWidth: 512,
                        textureHeight: 512,
                        waterNormals: new THREE.TextureLoader().load('https://raw.githubusercontent.com/mrdoob/three.js/master/examples/textures/waternormals.jpg', function ( texture ) {
                            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
                        }),
                        alpha: 1.0,
                        sunDirection: new THREE.Vector3(),
                        sunColor: 0xffffff,
                        waterColor: 0x001e0f,
                        distortionScale: 3.7,
                        fog: scene.fog !== undefined
                    }
                );
                water.rotation.x =- Math.PI / 2;
                scene.add(water);

                const waterUniforms = water.material.uniforms;
                return water;
            }

            // When called updates sun position
            this.updateSun = function(){
                buildSun()
                renderer.render(scene, camera);
            }
            const sizes = {
                width: window.innerWidth,
                height: window.innerHeight
            }
            const cursor = {}
            cursor.x = 0
            window.addEventListener('mousemove', (event) => {
                cursor.x = event.clientX / sizes.width - 0.5
            })
            const clock = new THREE.Clock()
            let previousTime = 0

            this.update = function() {
                // Animates water
                water.material.uniforms[ 'time' ].value += 1.0 / 60.0;
                const elapsedTime = clock.getElapsedTime()
                const deltaTime = elapsedTime - previousTime
                previousTime = elapsedTime
                const parallaxX = cursor.x * 50
                camera.position.x += (parallaxX - camera.position.x)  * deltaTime
                renderer.render(scene, camera);
            }

            //Rezises when window is changed
            function onWindowResize() {

                if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
                    // camera.aspect = window.innerWidth / (window.innerHeight+200);
                    // camera.updateProjectionMatrix();
                    // renderer.setSize( window.innerWidth, (window.innerHeight+200));
                }
                else{
                    camera.aspect = window.innerWidth / window.innerHeight;
                    camera.updateProjectionMatrix();
                    renderer.setSize( window.innerWidth, window.innerHeight);
                }

            }
            window.addEventListener('resize', onWindowResize);
        }

        const canvas = document.getElementById("canvas");
        const sceneManager = new SceneManager(canvas);

        //Animates the water
        function animate() {
            requestAnimationFrame(animate);
            sceneManager.update();
        }
        animate();

        // Updates sun every ten seconds
        const interval = setInterval(() => {
            function animate() {
                sceneManager.updateSun();
            }
            animate();

        }, 10000);
        return () => {
            clearInterval(interval);
        };

    },[])

    return (
        <div>
            <div id="canvas" ></div>
        </div>
    )

}

export default Wave3