import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import '../styles/kick.css'
import image2 from "../../assets/Double Cabinet v2.png"

const Kickstarter = () =>{

    const inViewport = () =>{
        const items = document.querySelectorAll('.reveal');
        items.forEach((box)=>{
            const rect = box.getBoundingClientRect();

            if (rect.top < 175) {
                box.style.opacity = 0;
            }
            else if(rect.bottom>window.innerHeight){
                box.style.opacity = 0;
            }
            else {
                box.style.opacity = 1;
            }

        })

    }

    useEffect(() => {
        window.addEventListener('scroll', inViewport, { passive: true });

        return () => {
            window.removeEventListener('scroll', inViewport);
        };
    }, []);



    return(<section className={'kick_section'}>
            <div className="kick_container">

                <div className="content_container">
                    <div className="kick_fin">
                        <div className="details">
                            <h4 className={'reveal'}>Vertical Solar</h4>
                            <h2 className={'reveal'}>Solar Cabinets</h2>
                            <p className={'reveal'}>Compact and cost-effective off-grid solution for powering equipment with limited space. Comes with high-quality components and a 10-year warranty.</p>
                            <div className="shop-social left-pad reveal">
                                <a href="https://www.instagram.com/lazerthrust/?hl=en" className={'social-links'}>

                                    <i className="uil uil-instagram-alt">
                                    </i>
                                </a>
                                <a href="https://www.youtube.com/channel/UCTt7KfAF7aMuyxZuRejDy7Q" className={'social-links'}>
                                    <i className="uil uil-youtube">
                                    </i>
                                </a>
                                <a href="https://www.facebook.com/LAZERTHRUST/" className={'social-links'}>
                                    <i className="uil uil-facebook">
                                    </i>
                                </a>
                                <a href="https://twitter.com/lazerthrust" className={'social-links'}>
                                    <i className="uil uil-twitter">
                                    </i>
                                </a>
                                <h2 className={'reveal'} style={{fontSize:"2rem",marginTop:'-20px'}}>Pre-Order coming soon!</h2>
                            </div>
                            {/*<a className={'link_kick'} ><button  className={'kickButton reveal'}><span>Pre-Order Coming Soon</span></button></a>*/}
                        </div>

                        <div className="imager reveal">
                            <img src={image2} alt="Solar cabinet sneak peek"/>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}
export default Kickstarter;