import React, {useEffect, useState,useRef} from "react";
import Night from './components/backgrounds/Night'
import Day from './components/backgrounds/Day'
import './assets/FrankfurterPlain.otf'
const App =()=> {
    const [currentTime,changeTime] = useState(new Date())

    //Function that checks every thirty seconds what time it is and adds
    //to state
    useEffect(()=>{

        const interval = setInterval(() => {
            const item = new Date();
            changeTime(item);

        }, 30000);
        return () => {
            clearInterval(interval);
        };
    },[])

    //Returns Night or day version of code based on hours
    return(<div>
            {currentTime.getHours() >= 18||currentTime.getHours()<6
                ? (<Night time={currentTime}/>)
                : (<Day hour={currentTime.getHours()}/>)
            }
        </div>
    )


}
export default App;
